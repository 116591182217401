import React from 'react'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Home from './Home'
import AddyMuncher from './screens/AddyMuncher'
import CardGame from './screens/CardGame'
import DayOne from './screens/DayOne'
import DungeonAdventure from './screens/DungeonAdventure'
import HappyBirthday from './screens/HappyBirthday'
import MinionCatch from './screens/MinionCatch'
import PriceIsKindaRight from './screens/PriceIsKindaRight'
import RockPaperScissor from './screens/RockPaperScissors'
import TrumpShot from './screens/TrumpShot'

function App() {
    return (
        <Router>
            {/* Routes */}
            <div className="">
                <Routes>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="/rock-paper-scissors"
                        element={<RockPaperScissor />}
                    />
                    <Route
                        path="/day-one"
                        element={<DayOne />}
                    />
                    <Route
                        path="/minion-catch"
                        element={<MinionCatch />}
                    />
                    <Route
                        path="/dungeon-adventure"
                        element={<DungeonAdventure />}
                    />
                    <Route
                        path="/trump-shot"
                        element={<TrumpShot />}
                    />
                    <Route
                        path="/addy-muncher"
                        element={<AddyMuncher />}
                    />
                    <Route
                        path="/happy-birthday"
                        element={<HappyBirthday />}
                    />
                    <Route
                        path="/price-guess"
                        element={<PriceIsKindaRight />}
                    />
                    <Route
                        path="/card-game"
                        element={<CardGame />}
                    />
                </Routes>
            </div>
        </Router>
    )
}

export default App
