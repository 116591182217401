import React, { useEffect, useState } from 'react'

import { FaStar } from 'react-icons/fa'
import { motion } from 'framer-motion'

const cardBase = `rounded-2xl flex flex-col justify-start absolute h-[450px] w-[310px] bg-white shadow-md cursor-grab overflow-hidden`
const cardBasicBorders = 'rounded-2xl border-[10px] border-gradient-to-br from-gray-900 via-gray-900 to-gray-900'
const cardHoloBase = `rounded-2xl flex flex-col justify-start absolute h-[450px] w-[310px] bg-gradient-to-b from-gray-100 via-gray-200 to-gray-100 shadow-md cursor-grab overflow-hidden`
const cardHoloBorders = 'rounded-2xl border-[10px]'
const cardFullBorders = 'rounded-2xl border-[10px] border-yellow-500'
const cardMargins = 'px-3.5 py-1.5'

const Card = ({ card, removeCard, active }) => {
    const [showConfetti, setShowConfetti] = useState(false)

    useEffect(() => {
        if (showConfetti) {
            // Trigger the confetti effect when the card is revealed
            setShowConfetti(true)

            // Automatically hide confetti after 1 second
            const timer = setTimeout(() => {
                setShowConfetti(false)
            }, 1000)

            return () => clearTimeout(timer)
        }
    }, [showConfetti])

    const [leaveX, setLeaveX] = useState(0)

    const onDragEnd = (event, info) => {
        if (info.offset.x > 150) {
            setLeaveX(1000)
            removeCard(card, 'like')
        } else if (info.offset.x < -100) {
            setLeaveX(-1000)
            removeCard(card, 'nope')
        }
    }

    return (
        <>
            {active ? (
                <motion.div
                    drag="x" // Restrict dragging to the horizontal axis
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={onDragEnd}
                    initial={{
                        scale: 1,
                    }}
                    animate={{
                        scale: 1.05,
                    }}
                    exit={{
                        x: leaveX,
                        y: 0,
                        opacity: 0,
                        scale: 0.5,
                        transition: { duration: 0.2 },
                    }}
                    className={`${cardBase}`}>
                    {card.cardType === 'basic' ? (
                        <BasicCardContent card={card} />
                    ) : card.cardType === 'holo' ? (
                        <HoloCardContent card={card} />
                    ) : (
                        <FullArtCardContent card={card} />
                    )}
                </motion.div>
            ) : (
                <div className={`${cardBase}`}>
                    {card.cardType === 'basic' ? (
                        <BasicCardContent card={card} />
                    ) : card.cardType === 'holo' ? (
                        <HoloCardContent card={card} />
                    ) : (
                        <FullArtCardContent card={card} />
                    )}
                </div>
            )}
        </>
    )
}

// Basic Card
const BasicCardContent = ({ card }) => {
    return (
        <div className={`${cardBase} ${cardMargins} ${cardBasicBorders} h-full flex flex-col`}>
            {/* Name and Type */}
            <div className="flex flex-row justify-between mb-0.5">
                <p className="text-gray-800 font-semibold text-lg font-minecraft">{card.name}</p>
                <p className="text-gray-800 font-semibold text-lg font-minecraft">{card.type}</p>
            </div>

            {/* Image */}
            <img
                src={card.image}
                className="h-[40%] w-full rounded-[3px] mb-5 border-4 border-gray-300 pointer-events-none"
            />

            {/* Ability */}
            <div>
                <div className="flex flex-row justify-between mb-1 font-minecraft">
                    <p className="text-gray-800 font-semibold text-lg mr-3 font-minecraft">{card.ability}</p>
                    <p className="text-gray-800 font-semibold text-lg font-minecraft">
                        {card.value}
                        <span>{card?.valueIcon}</span>
                    </p>
                </div>

                {/* Description*/}
                <p className="text-gray-600 font-medium text-sm font-minecraft">{card.description}</p>
            </div>

            {/* Footer Section */}
            <div className="mt-auto">
                <hr className="border-t-2 border-gray-300" />

                <div className="flex flex-row w-full justify-between">
                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        <p className="text-gray-800 font-medium text-xs font-minecraft">Weakness:</p>
                        <p className="text-gray-800 text-xs font-minecraft">{card.weakness}</p>
                    </div>

                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        {Array.from({ length: card.stars }, (_, index) => (
                            <FaStar
                                className="text-orange-500"
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Holo Card
const HoloCardContent = ({ card }) => {
    return (
        <div
            className={`${cardHoloBase} ${cardMargins} ${cardHoloBorders} h-full flex flex-col`}
            style={{
                borderImage: 'linear-gradient(45deg, #5D5D5D, #A9A9A9, #E0E0E0) 1',
            }}>
            {/* Name and Type */}
            <div className="flex flex-row justify-between mb-0.5">
                <p className="text-gray-800 font-semibold text-lg font-minecraft">{card.name}</p>
                <p className="text-gray-800 font-semibold text-lg font-minecraft">{card.type}</p>
            </div>

            {/* Image */}
            <img
                src={card.image}
                className="h-[40%] w-full mb-5 border-4 border-gray-400 pointer-events-none"
            />

            {/* Ability */}
            <div>
                <div className="flex flex-row justify-between mb-1 font-minecraft">
                    <p className="text-gray-800 font-semibold text-lg mr-3 font-minecraft">{card.ability}</p>
                    <div className="flex flex-row items-center gap-1">
                        <p className="text-gray-800 font-semibold text-lg font-minecraft">{card.value}</p>
                        {card?.valueIcon}
                    </div>
                </div>

                {/* Description*/}
                <p className="text-gray-600 font-medium text-sm font-minecraft">{card.description}</p>
            </div>

            {/* Footer Section */}
            <div className="mt-auto">
                <hr className="border-t-2 border-gray-300" />

                <div className="flex flex-row w-full justify-between">
                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        <p className="text-gray-800 font-medium text-xs font-minecraft">Weakness:</p>
                        <p className="text-gray-800 text-xs font-minecraft">{card.weakness}</p>
                    </div>

                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        {Array.from({ length: card.stars }, (_, index) => (
                            <FaStar
                                className="text-orange-500"
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Full Art Card
const FullArtCardContent = ({ card }) => {
    return (
        <div
            className={`${cardFullBorders} ${cardMargins} h-full flex flex-col background-image`}
            style={{
                backgroundImage: `url(${card.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            {/* Name and Type */}
            <div className="flex flex-row justify-between mb-0.5">
                <p className="text-yellow-500 font-semibold text-lg font-minecraft">{card.name}</p>
                <p className="text-yellow-500 font-semibold text-lg font-minecraft">{card.type}</p>
            </div>

            {/* Footer Section */}
            <div className="mt-auto">
                {/* Ability */}
                <div className="mb-6">
                    <div className="flex flex-row justify-between mb-1 font-minecraft">
                        <p className="text-yellow-500 font-semibold text-lg mr-3 font-minecraft">{card.ability}</p>
                        <p className="text-yellow-500 font-semibold text-lg font-minecraft">
                            {card.value}
                            {card?.valueIcon}
                        </p>
                    </div>

                    {/* Description*/}
                    <p className="text-white font-medium text-sm font-minecraft">{card.description}</p>
                </div>

                <hr className="border-t-2 border-gray-300" />

                <div className="flex flex-row justify-between">
                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        <p className="text-white font-medium text-xs font-minecraft">Weakness:</p>
                        <p className="text-white text-xs font-minecraft">{card.weakness}</p>
                    </div>
                    <div className="flex flex-row pt-2 mb-1 gap-1.5">
                        {Array.from({ length: card.stars }, (_, index) => (
                            <FaStar
                                className="text-yellow-500"
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
