import React, { useEffect, useState } from 'react'

import Papa from 'papaparse'

const PriceIsKindaRight = () => {
    const [products, setProducts] = useState([])
    const [currentProduct, setCurrentProduct] = useState(null)
    const [guess, setGuess] = useState('')
    const [feedback, setFeedback] = useState('')
    const [round, setRound] = useState(1)
    const [score, setScore] = useState(0)
    const [isRevealingPrice, setIsRevealingPrice] = useState(false)
    const [animatedPrice, setAnimatedPrice] = useState(0)
    const [pointsEarned, setPointsEarned] = useState(0)

    const parsePrice = (priceString) => {
        const cleanedPrice = priceString.replace(/[^0-9.]/g, '')
        return parseFloat(cleanedPrice)
    }

    useEffect(() => {
        const fetchAndParseCSV = async () => {
            try {
                const response = await fetch('../PriceIsKindaRight/product_data.csv')
                const csvData = await response.text()

                Papa.parse(csvData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (result) => {
                        if (result.data && result.data.length > 0) {
                            setProducts(result.data)
                            const randomIndex = Math.floor(Math.random() * result.data.length)
                            setCurrentProduct(result.data[randomIndex])
                        } else {
                            console.error('No valid data found in the CSV file.')
                        }
                    },
                    error: (error) => {
                        console.error('Error parsing CSV: ', error)
                    },
                })
            } catch (error) {
                console.error('Error fetching CSV file: ', error)
            }
        }

        fetchAndParseCSV()
    }, [])

    const calculateDynamicPoints = (actualPrice, guessedPrice) => {
        const difference = Math.abs(actualPrice - guessedPrice)
        const ratio = difference / actualPrice // Ratio of difference to actual price

        if (ratio === 0) return 1000 // Perfect guess

        // Adjusted exponential decay for scoring
        const maxPoints = 1000 // Points for a perfect guess
        const penalty = Math.exp(-5 * ratio) // Exponential decay based on ratio
        const points = Math.round(maxPoints * penalty) // Calculate points

        // Grant points only if the ratio is within 70% of the actual price
        return ratio <= 0.7 ? points : 0 // 0 points for guesses >70% off
    }

    const handleGuess = () => {
        if (!currentProduct) return

        const actualPrice = parsePrice(currentProduct.price)
        const guessedPrice = parseFloat(guess)

        if (isNaN(guessedPrice)) {
            setFeedback('❌ Please enter a valid number.')
            return
        }

        const points = calculateDynamicPoints(actualPrice, guessedPrice)
        setScore((prevScore) => prevScore + points)
        setPointsEarned(points)

        setFeedback(
            guessedPrice === actualPrice
                ? '🎉 Correct! You guessed the exact price.'
                : `❌ Incorrect. The actual price is $${actualPrice.toFixed(2)}.`
        )

        // Start the price reveal animation
        setIsRevealingPrice(true)
        let start = 0
        const interval = setInterval(() => {
            start += actualPrice / 100 // Increment gradually
            if (start >= actualPrice) {
                clearInterval(interval)
                setAnimatedPrice(actualPrice)
            } else {
                setAnimatedPrice(start)
            }
        }, 10)
    }

    const handleNextRound = () => {
        if (round < 5) {
            setRound((prevRound) => prevRound + 1)
            const randomIndex = Math.floor(Math.random() * products.length)
            setCurrentProduct(products[randomIndex])
            setGuess('')
            setFeedback('')
            setIsRevealingPrice(false)
            setAnimatedPrice(0)
        } else {
            setRound((prevRound) => prevRound + 1)
            setFeedback(`Game Over! Your total score is ${score}. Refresh to play again.`)
            setCurrentProduct(null) // End the game
        }
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            {/* Total Points Tracker */}
            <div className="absolute top-4 left-4 bg-blue-500 text-white px-4 py-2 rounded">
                <p>Total Points: {score}</p>
            </div>

            {currentProduct && round <= 5 ? (
                <div className="bg-white shadow-md rounded-lg p-6 max-w-sm text-center relative">
                    <h2 className="text-2xl text-gray-700 font-bold mb-8">Round {round}/5</h2>
                    <img
                        src={currentProduct['image-src']}
                        alt={currentProduct.title}
                        className="w-full h-48 object-contain mb-5"
                    />
                    <h2 className="text-xl text-gray-700 font-semibold mb-2 text-left">
                        {currentProduct.title.length > 60
                            ? `${currentProduct.title.slice(0, 60)}...`
                            : currentProduct.title}
                    </h2>

                    <div className="flex w-full justify-start gap-1">
                        <span className="text-green-600">{currentProduct.rank} </span>
                        <span className="text-gray-500">in</span>
                        <span className="text-gray-500">{currentProduct.topLevelCategory}</span>
                    </div>

                    <div className="flex w-full justify-start gap-1 mb-8">
                        <span className="text-orange-500">{currentProduct.ratings}</span>
                        <span className="text-gray-400">|</span>
                        <span className="text-gray-500 font-semibold">{currentProduct.reviewCount} reviews</span>
                    </div>

                    {isRevealingPrice ? (
                        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 flex flex-col justify-center items-center">
                            <h3 className="text-xl text-gray-800 font-bold mb-2">Actual Price</h3>
                            <p className="text-4xl font-extrabold text-green-500">${animatedPrice.toFixed(2)}</p>
                            <p className="text-lg text-gray-800 mt-2">Your Guess: ${parseFloat(guess).toFixed(2)}</p>
                            {animatedPrice >= parsePrice(currentProduct.price) && (
                                <div className="mt-4">
                                    <p className="text-lg font-semibold">You earned {pointsEarned} points!</p>
                                    <button
                                        onClick={handleNextRound}
                                        className="bg-blue-500 text-white py-2 px-10 rounded hover:bg-blue-600 mt-4">
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <input
                                id="price"
                                type="text"
                                autoComplete="off"
                                value={guess}
                                onChange={(e) => setGuess(e.target.value)}
                                placeholder="$0.00"
                                className="border rounded py-2 w-full mb-4 text-xl text-center"
                            />
                            <button
                                onClick={handleGuess}
                                className="bg-blue-500 text-white py-2 px-10 rounded-md hover:bg-blue-600">
                                Guess Price
                            </button>
                            {feedback && <p className="mt-4 text-lg font-semibold">{feedback}</p>}
                        </>
                    )}
                </div>
            ) : (
                round > 5 && (
                    <div className="bg-white shadow-md rounded-lg p-6 max-w-sm text-center border-2">
                        <h2 className="text-2xl font-bold mb-4">Game Over!</h2>
                        <p className="text-lg">Your total score is {score}.</p>
                        <p className="text-gray-500">Refresh to play again!</p>
                    </div>
                )
            )}
        </div>
    )
}

export default PriceIsKindaRight
