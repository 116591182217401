import React, { useState } from 'react'

import { FaHandsHelping, FaHeart, FaHourglass, FaKiwiBird, FaShieldAlt } from 'react-icons/fa'
import { AnimatePresence } from 'framer-motion'

import Card from '../components/Card' // You will provide the Card component

const RotateIcon = (props) => (
    <svg
        fill="none"
        shapeRendering="geometricPrecision"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        width={20}
        height={20}
        viewBox="0 0 24 24"
        {...props}>
        <path d="M1 4v6h6" />
        <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10" />
    </svg>
)

const CardGame = () => {
    // Dummy data for the cards
    const DUMMY_CARDS = [
        {
            cardType: 'full',
            id: 1,
            name: '4 Months With You',
            type: <FaHeart size={20} />,
            image: '/CardGame/gift.png',
            stars: 5,
            ability: 'Dating',
            value: '1000',
            description: "Wouldn't want anyone else!",
            weakness: 'N/A',
        },
        {
            cardType: 'holo',
            id: 2,
            name: 'Be My Valentines?',
            type: <FaHeart size={20} />,
            image: '/CardGame/valentines.png',
            stars: 4,
            ability: 'Go out with me on Feb 14, 2025?',
            value: 'Yes?',
            description: 'Swipe left for yes. Swipe right for No.',
            weakness: '=)',
        },
        {
            cardType: 'holo',
            id: 3,
            name: 'Pokemon Addict',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/addict.png',
            stars: 2,
            ability: 'Free Pack',
            value: `12`,
            valueIcon: <FaHourglass className="mb-1.5 text-purple-600" />,
            description: 'Get a free pack on me!',
            weakness: 'Gold',
        },
        {
            cardType: 'full',
            id: 4,
            name: 'Girlfriend',
            type: <FaKiwiBird size={20} />,
            image: '/CardGame/girlfriend.png',
            stars: 3,
            ability: 'The Question',
            value: '???',
            description: 'Will she say yes or no? Or will she start crying.',
            weakness: 'Yonten',
        },
        {
            cardType: 'holo',
            id: 5,
            name: 'LeBron Parlay',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/parlay.png',
            stars: 3,
            ability: '3-Legs',
            value: '+$40',
            description: 'This one is a lock. I promise.',
            weakness: "3-Pt'ers",
        },
        {
            cardType: 'basic',
            id: 6,
            name: 'A Soju Shot',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/soju.png',
            stars: 1,
            ability: 'Tipsy',
            value: '3.5 Oz',
            description: '아파트, 아파트. 아파트, 아파트. Uh, uh-huh, uh-huh',
            weakness: 'Lightweight',
        },
        {
            cardType: 'holo',
            id: 7,
            name: 'Coupon',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/coupon.png',
            stars: 2,
            ability: 'One uh...',
            value: '15 Mins',
            description: 'IYKYK',
            weakness: 'No.',
        },
        {
            cardType: 'basic',
            id: 8,
            name: 'Chuck the Rat',
            type: <FaKiwiBird size={20} />,
            image: '/CardGame/chuck.png',
            stars: 1,
            ability: 'Unlimited Plays',
            value: '1 Hour',
            description:
                "Use Groupon to get unlimited plays for 1 hour for only $19.95. Just don't touch any of the surfaces or you'll catch something bad.",
            weakness: 'Sticky',
        },
        {
            cardType: 'basic',
            id: 9,
            name: 'Brooklyn Mirage',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/rave.png',
            stars: 1,
            ability: 'Rave',
            value: '10mg of [censored]',
            description: "Who doesn't love $5 water bottles and $13 shots? Let's go to the next one.",
            weakness: 'Drama',
        },
        {
            cardType: 'holo',
            id: 10,
            name: 'Cheese Bread',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/dominos.png',
            stars: 3,
            ability: 'Cheesy Goodness',
            value: '20',
            description: 'Disgarsting.',
            weakness: 'Lactose Intol.',
        },
        {
            cardType: 'basic',
            id: 11,
            name: 'UberEats Promo',
            type: <FaHandsHelping size={20} />,
            image: '/CardGame/uber.png',
            stars: 1,
            ability: 'Discount',
            value: '-$8.75',
            description: 'Get 40% off you next order of $25 or more. What could go wrong?',
            weakness: 'Insufficient Funds',
        },
        {
            cardType: 'holo',
            id: 12,
            name: 'Tyrannosaurus',
            type: <FaKiwiBird />,
            image: '/CardGame/dinosaur.png',
            stars: 2,
            ability: 'Stand Still',
            value: '$0',
            description: "Free because Tsesen's mom has some sort of discount?",
            weakness: 'Meteors',
        },
        {
            cardType: 'basic',
            id: 13,
            name: 'Boba Date',
            type: <FaShieldAlt size={22} />,
            image: '/CardGame/boba.png',
            stars: 1,
            ability: 'Black Sugar',
            value: '200 Cal',
            description: '25% Sugar. No Ice.',
            weakness: 'Coffee Dates',
        },
        {
            cardType: 'full',
            id: 14,
            name: 'DM Slide',
            type: <FaHandsHelping size={22} />,
            image: '/CardGame/insta.png',
            stars: 3,
            ability: 'New Message Request',
            value: '80',
            description: "Which guy did my cousin send to my DM's this time?",
            weakness: 'Deny',
        },
        {
            cardType: 'basic',
            id: 15,
            name: 'Hinge',
            type: <FaKiwiBird size={22} />,
            image: '/CardGame/hinge.png',
            stars: 1,
            ability: 'Swipe',
            value: '40',
            description: "'I'll fall for you if you trip me...'",
            weakness: 'Left Swipes',
        },
    ]

    const [cards, setCards] = useState(DUMMY_CARDS)
    const [history, setHistory] = useState([])

    // Index of the last card in the stack
    const activeIndex = cards.length - 1

    // Remove a card from the stack
    const removeCard = (oldCard, swipe) => {
        setHistory((current) => [...current, { ...oldCard, swipe }])
        setCards((current) => current.filter((card) => card.id !== oldCard.id))
    }

    // Undo the last swipe
    const undoSwipe = () => {
        const newCard = history.pop()
        if (newCard) {
            setHistory((current) => current.filter((card) => card.id !== newCard.id))
            setCards((current) => [...current, newCard])
        }
    }

    return (
        <div
            className="relative flex flex-col justify-center items-center w-full h-screen"
            style={{
                background: 'linear-gradient(to bottom, #9ecbfa, #d8f0fe)',
            }}>
            <AnimatePresence>
                {cards.map((card, index) => (
                    <Card
                        key={card.id}
                        active={index === activeIndex}
                        removeCard={removeCard}
                        card={card}
                    />
                ))}
            </AnimatePresence>
            {cards.length === 0 && <span className="text-white text-xl">End of Stack</span>}
            <footer className="absolute bottom-4 flex items-center">
                <div className="flex flex-col items-center space-y-2">
                    <button
                        disabled={history.length === 0}
                        className="w-14 h-14 rounded-full text-black bg-white inline-flex border border-gray-800 justify-center items-center disabled:cursor-not-allowed"
                        onClick={undoSwipe}
                        aria-label="Undo Swipe">
                        <RotateIcon strokeWidth={3} />
                    </button>
                    <span className="text-xs text-black font-medium">Undo</span>
                </div>
            </footer>
        </div>
    )
}

export default CardGame
